import { render, staticRenderFns } from "./FormItemControl.vue?vue&type=template&id=721f6fbe&scoped=true&"
import script from "./FormItemControl.vue?vue&type=script&lang=ts&"
export * from "./FormItemControl.vue?vue&type=script&lang=ts&"
import style0 from "./FormItemControl.vue?vue&type=style&index=0&id=721f6fbe&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "721f6fbe",
  null
  
)

export default component.exports